'use strict';

var $ = require('jquery'),
    moment = require('moment'),
    validateEmail = function validateEmail(email) {
        var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return re.test(email);
    };

var $newsletterSignup = $('#newsletterSignup'),
    $startAConversation = $('#startAConversation');

$newsletterSignup.find('.link').on('click', function(){
    var emailAddress = $newsletterSignup.find('input').val();

    $newsletterSignup.removeClass('error');
    $newsletterSignup.removeClass('loading');
    $newsletterSignup.removeClass('success');
    $newsletterSignup.find('.link').prop('disabled', true);

    if(validateEmail(emailAddress)) {
        $newsletterSignup.addClass('loading');

        $.ajax({
            type : 'POST',
            url : '/newslettersignup',
            dataType : 'json',
            contentType: 'application/json; charset=UTF-8',
            data : JSON.stringify({
                emailAddress : emailAddress,
                dateTime : moment().format()
            })
        })
            .success(function() {
                $newsletterSignup.find('.status-message').text('Success! Please check your email to confirm subscription.');
                $newsletterSignup.addClass('success');
            })
            .fail(function() {
                $newsletterSignup.find('.status-message').text('Signup did not work. Please try again.');
                $newsletterSignup.addClass('error');
            })
            .always(function() {
                $newsletterSignup.find('.link').prop('disabled', false);
                $newsletterSignup.removeClass('loading');
            });

    } else {
        $newsletterSignup.find('.status-message').text('Please enter an email address to signup with.');
        $newsletterSignup.addClass('error');
        $newsletterSignup.find('.link').prop('disabled', false);
    }

});

$startAConversation.find('.panel-button').on('click', function() {
    var payload = {
        name : $startAConversation.find('#name').val(),
        email : $startAConversation.find('#email').val(),
        phone : $startAConversation.find('#phone').val(),
        message : $startAConversation.find('#body').val()
    };

    $startAConversation.removeClass('error-items-missing');
    $startAConversation.removeClass('error-general');
    $startAConversation.removeClass('loading');
    $startAConversation.removeClass('success');
    $startAConversation.find('.panel-button').prop('disabled', true);

    if(payload.name && validateEmail(payload.email)) {
        $startAConversation.addClass('loading');

        $.ajax({
            type : 'POST',
            url : '/sendemail',
            contentType: 'application/json; charset=UTF-8',
            data : JSON.stringify(payload)
        })
            .success(function() {
                $startAConversation.find('.status-message').text('Success!');
                $startAConversation.addClass('success');
            })
            .fail(function() {
                $startAConversation.find('.status-message').text('Something went wrong. Please try again.');
                $startAConversation.addClass('error-general');
            })
            .always(function() {
                $startAConversation.find('.link').prop('disabled', false);
                $startAConversation.removeClass('loading');
            });
    } else {
        $startAConversation.find('.status-message').text('Please enter your name and email address to contact us.');
        $startAConversation.addClass('error-items-missing');
        $startAConversation.find('.panel-button').prop('disabled', false);
    }

});
